
;(function( $ ) {
    $.fn.parallax = function ( userSettings ) {
        var options = $.extend( {}, $.fn.parallax.defaults, userSettings );

        return this.each(function () {
            var $this   = $(this),
                isX     = options.axis === 'x',
                dir		= options.dir,
                origPos = ( $this.css( 'background-position' ) || '' ).split(' '),
                origX   = $this.css( 'background-position-x' ) || origPos[ 0 ],
                origY   = $this.css( 'background-position-y' ) || origPos[ 1 ],
                origW	= $this.css( 'background-size' ).split(' ')[0],
                dist    = function () {
	                if (isX) {
		                return $(window).scrollTop() - $this.offset().top;
	                } else {
		                return -$(window).scrollTop();
	                }
                    //return -$( window )[ isX ? 'scrollTop' : 'scrollTop' ]();	//scrollLeft
                };
                
            if ( ! isX) {
            	$this.css( 'background-attachment', 'fixed' );
            }
            
            $this.addClass( 'inview' );

            $this.bind('inview', function ( e, visible ) {
                $this[ visible ? 'addClass' : 'removeClass' ]( 'inview' );
            });

			$( window ).bind( 'resize', function() {
				if (isX) {
					var currentBgImage = $this.css('background-image');
					$this.removeAttr('style');
					
					if (currentBgImage) {
						$this.css('background-image', currentBgImage);
					}
					
					origPos = ( $this.css( 'background-position' ) || '' ).split(' ');
	                origX   = $this.css( 'background-position-x' ) || origPos[ 0 ];
	                origY   = $this.css( 'background-position-y' ) || origPos[ 1 ];
	                origW	= $this.css( 'background-size' ).split(' ')[0];
	            }
			});
			
            $( window ).bind( 'scroll', function () {
                if ( ! $this.hasClass( 'inview' )) { return; }
                
                var newX = 0;
                var newY = 0;
                var newDistX = 0;
                var newDistY = 0;
                
                if (isX) {
	                if (origX.indexOf("%") > -1) {
		                if (origW.indexOf("%") > -1) {
			                newX = ($(window).width() - parseFloat(origW.replace("%", ""))) / 100 * parseFloat(origX.replace("%", ""));
		                } else {
			                newX = ($(window).width() - parseFloat(origW.replace("px", ""))) / 100 * parseFloat(origX.replace("%", ""));
		                }
	                } else {
		                newX = parseFloat(origX.replace("px", ""));
	                }
		                
	                if (dir === 'right') {
		                newDistX = newX + ( dist() * options.speed );
	                } else {
		                newDistX = newX - ( dist() * options.speed );
	                }
	            } else {
		            if (origY.indexOf("%") > -1) {
			            newY = ($this.offset().top + ($this.height() / 100 * parseFloat(origY.replace("%", "")))) * -1;
		            } else {
		                newY = parseFloat(origY.replace("px", ""));
	                }
		            	            
	                newDistY = newY + ( dist() * options.speed );
	            }
	            	            
				var xPos = isX ? newDistX + 'px' : origX,
                    yPos = isX ? origY : newDistY + 'px';
                $this.css( 'background-position', xPos + ' ' + yPos );
            });
        });
    };

    $.fn.parallax.defaults = {
        start: 0,
        stop: $( document ).height(),
        speed: 1,
        axis: 'x',
        dir: 'right'
    };

})( jQuery );
