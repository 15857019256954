// globs
var waypointEnabled = true;

function isMobile() {
	return (!! Modernizr.touch);
}

function calcSetOpacity(elem, currentScrollPos, elemPosX, elemHeight, offsetX) {
    if (currentScrollPos >= (elemPosX - offsetX)) {
		var calcVal = ((currentScrollPos - elemPosX + offsetX) / elemHeight);
			
		if (calcVal <= 1) {				
			elem.css({'opacity' : 1 - calcVal});
		}
	} else {
		elem.css({'opacity' : 1});
	}
}

function scrollToPageSection(currentUrl) {
	var currentId = currentUrl.substring(currentUrl.indexOf("#") + 1);
	var currentOffset = 0;
	var scrollSpeed = 800;
	
	if (currentId !== "top") { currentOffset = 0; }
	
	if (currentId === "bottom") { scrollSpeed = 5000; }
	
	if ($("#" + currentId).length) {
		var anchorPosition = Math.floor($("#" + currentId).offset().top) - currentOffset;
		$('html, body').stop().animate({scrollTop: anchorPosition}, scrollSpeed, function() {
			waypointEnabled = true;
		});
	}
}

function initializeGoogleMap() {
	var mapCanvas = $("#map-canvas");
	var lat = 47.071386;
	var lng = 15.42840958;
	var markerTitle = "Pluto Vermögensverwaltung GmbH";
	
	if (mapCanvas.attr('data-lat')) { lat = mapCanvas.attr('data-lat'); }
	if (mapCanvas.attr('data-lng')) { lng = mapCanvas.attr('data-lng'); }
	if (mapCanvas.attr('data-title')) { markerTitle = mapCanvas.attr('data-title'); }
	
	var mapCenter = new google.maps.LatLng(lat, lng);
	
	var notMobile = true;
	var curZoom = 16;
	if (isMobile()) {
		notMobile = false;
		curZoom = 15;
	}
	
	google.maps.visualRefresh = false;
	
	var mapOptions = {
		center: mapCenter,
		zoom: curZoom,
		disableDefaultUI: true,
		zoomControl: false,
		scaleControl: false,
		scrollwheel: false,
		disableDoubleClickZoom: true,
		mapTypeControlOptions: {
			mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
		},
		draggable: notMobile
	};
	
	var infoWindow = new google.maps.InfoWindow({
		content: markerTitle
	});
         
	var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
	
	var markerSource = "http://pluto.at/2015/wp-content/themes/teilzwei/assets/images/pin.png";
	
	var marker = new google.maps.Marker({
		position: mapCenter,
		map: map,
		title: markerTitle,
		draggable: false,
		icon: new google.maps.MarkerImage(markerSource, null, null, null, new google.maps.Size(36, 55)),
		visible: true
	});
	
	infoWindow.open(map, marker);
	
	google.maps.event.addListener(marker, 'click', function() {
		infoWindow.open(map, marker);
	});
	
	google.maps.event.addDomListener(window, "resize", function() {
		var center = map.getCenter();
		google.maps.event.trigger(map, "resize");
		map.setCenter(center);
	});
}

function correctFlow() {
	if ($("#project-location-intro").length) {
		$("#project-location-intro").css("margin-top", $("#project-infos .wrapper").outerHeight() + "px");
	} else {
		$("#project-location").css("padding-top", $("#project-infos .wrapper").outerHeight() + "px");
	}
	
	if ($("#reference-location-intro").length) {
		$("#reference-location-intro").css("margin-top", $("#reference-infos .wrapper").outerHeight() + "px");
	} else {
		$("#reference-location").css("padding-top", $("#reference-infos .wrapper").outerHeight() + "px");
	}
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        // preload teaser image
        var teaserBg = $("#teaser .wrapper-bg-teaser");
        
        if (teaserBg.length) {
	        var teaserImageUrl = teaserBg.attr("data-src");
	        
			$('<img/>').attr('src', teaserImageUrl).one('load', function() {
				$("#teaser .wrapper-bg").fadeOut(function() {
					teaserBg.fadeTo('slow', 0.7);
				});
			});
        }
        	
		// waypoints
		var waypointArray = $('.waypoint').waypoint({
			handler: function(dir) {
				if (waypointEnabled) {
					$(".navlink a").removeClass("active");
									
					if (dir === "down") {
						$('.navlink a[href*=#' + this.element.id + ']').addClass("active");
					} else {
						$('.navlink a[href*=#' + this.element.id + ']').parent().prev().find('a:first').addClass("active");
					}
				}
			},
			offset: '40%',
			continuous: false
		});
		
		// smooth scrolling & waypoint handling
        $("a[href*='#']").on('click', function(e) {
			e.preventDefault();
			waypointEnabled = false;
			var navTrigger = $("#toggle-nav");
			
			if (navTrigger.is(":visible") && navTrigger.hasClass("active")) {
				navTrigger.toggleClass("active");
				navTrigger.siblings("nav").fadeToggle(300);
			}
			
			if ($(this).parent().hasClass("navlink")) {
				$(".navlink a").removeClass("active");
				$(this).addClass("active");
			} else if ($(this).hasClass("brand")) {
				$(".navlink a").removeClass("active");
			}
						
			scrollToPageSection($(this).attr('href'));
		});
				
		// google maps api
		function loadScript() {
			if ($('#map-canvas').length) {
				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = '//maps.googleapis.com/maps/api/js?key=AIzaSyAqmqksfcXPRGmEt86j2GviNZjn5Asrsik&sensor=false&callback=initializeGoogleMap';
				document.body.appendChild(script);
			}
		}
		
		window.onload = loadScript;
		
		if ( ! isMobile()) {
			// parallax
			$("#project-infos .wrapper-bg.para, #reference-infos .wrapper-bg.para").parallax({speed: 0.5, axis: 'y'});
		} else {
			$("html").addClass("is-mobile");
		}
				
		// lazy loading
		$("#projects div.lazy, #credentials div.lazy, #project-gallery div.lazy, #project-details div.lazy, #reference-gallery div.lazy").lazyload({
			effect: "fadeIn"
		});
		
		// lightbox
		lightbox.option({
			'albumLabel': 'Bild %1 von %2'
		});
		
		// mobile navigation
		$("#toggle-nav").on("click", function() {
			$(this).toggleClass("active");
			$(this).siblings("nav").fadeToggle(300);
		});
		
		// store last scroll position
		if ( $.cookie("scroll") !== null && $.cookie("location") !== null && $.cookie("location") === $(location).attr('href')) {
		    $(document).scrollTop( $.cookie("scroll") );
		    $.removeCookie("scroll");
		    $.removeCookie("location");
		}
		
		$('a.project-info, a.reference-info').on("click", function(e) {
			$.cookie("scroll", $(document).scrollTop() );
			$.cookie("location", $(location).attr('href'));
		});
		
	    var distance = $(window).scrollTop();
	    var scrollHint = $('.scroll-hint.scroll-up');
	    var curHeight = $(window).height();
	    var curWidth = 0;
		
		if (curWidth > 768) {
		    if (distance >= curHeight) {
			    scrollHint.stop(true,true).fadeTo(200, 1);
		    }
	    }
		    
		$(window).scroll(function() {
			curWidth = $(window).width();
			
			if (curWidth > 768) {
				distance = $(window).scrollTop();
				curHeight = $(window).height();
								
				if (distance >= curHeight) {
				    scrollHint.stop(true,true).fadeTo(800, 1);
			    } else {
				    scrollHint.hide();
			    }
			}
		});
		
		// project/reference location intro set absolute position to flow
		correctFlow();
		
		$(window).resize(function() {
			correctFlow();
			
			curWidth = $(window).width();
			
			if (curWidth > 768) {
				distance = $(window).scrollTop();
				curHeight = $(window).height();
								
				if (distance >= curHeight) {
				    scrollHint.stop(true,true).fadeTo(800, 1);
			    } else {
				    scrollHint.hide();
			    }
			}
		});

		// map button
		$('#map-button').on('click', function() {
			var curElem = $(this);
			var curAltText = curElem.attr('data-alt-text');
			var isActive = curElem.hasClass("active");
			var animHeight = isActive ? "0px" : "500px";
			
			$("#map").stop().animate({ height: animHeight }, { duration: 800, queue: false });
			
			if ( ! isActive) {
				$("html, body").stop().animate({ scrollTop: $(document).height() }, 800);
			}
			
			curElem.toggleClass("active");
			
			curElem.attr('data-alt-text', curElem.text());
			curElem.text(curAltText);
		});
		
		$("#xmas-btn-text-trigger").on('click', function() {
			var xMasBtn = $(this).closest('form').find('.btn-xmas');
			
			if ($('#addperson').is(':checked')) {
				xMasBtn.text(xMasBtn.data('single'));
			} else {
				xMasBtn.text(xMasBtn.data('double'));
			}
		});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        if ( ! isMobile()) {
	        var startParallaxAtPx = 50;
	        var teaserTitle = $("#teaser .title");
	        var teaserTitleOffsetTop = teaserTitle.offset().top;
	        var teaserTitleHeight = teaserTitle.outerHeight();
	        var teaserSubtitleHeight = $("#teaser .subtitle").outerHeight();
	        
	        var specialTeaserLinkContainer = $("#teaser .special-teaser-link-container");
	        var isSpecialTeaserLink = false;
	        
	        if (specialTeaserLinkContainer.length) {
		        isSpecialTeaserLink = true;
		        var teaserLinkOffsetTop = specialTeaserLinkContainer.offset().top;
		        var teaserLinkHeight = specialTeaserLinkContainer.outerHeight();
		    }
		    
		    var nav = $('header.banner');
		    var distance = 0;
		    var curHeight = 0;
		    var curWidth = 0;
		    var threshold = 0;
		    var cssBottom = 0;
		    var cssPosition = 0;
		    var teaserHeight = $("#teaser").outerHeight();
		    var navHeight = nav.outerHeight();
		    var teaserParatext = $("#teaser .paratext");
		    var teaserLinkContainer = $("#teaser .special-teaser-link-container");
		    
		    $(window).scroll(function() {
				curWidth = $(window).width();
				
				if (curWidth > 768) {
					distance = $(window).scrollTop();
					curHeight = $(window).height();
					threshold = curHeight - navHeight;
					cssBottom = 0;
					cssPosition = "absolute";
					
					if (teaserHeight > curHeight) {
						threshold = teaserHeight - navHeight;
						
						if ((teaserHeight - curHeight) > distance) {
							cssPosition = "fixed";
						} else {
							cssBottom = curHeight - teaserHeight;	
						}					
					}
					
					if (distance >= threshold) {
						nav.css({
							"position": "fixed",
							"top": 0,
							"bottom": "auto"
						});
					}
					else {
						nav.css({
							"position": cssPosition,
							"top": "auto",
							"bottom": cssBottom
						});
					}
					
					// Parallax Effects
					calcSetOpacity(teaserParatext, distance, teaserTitleOffsetTop, (teaserTitleHeight + teaserSubtitleHeight - startParallaxAtPx), startParallaxAtPx);
					
					if (isSpecialTeaserLink) {
						calcSetOpacity(teaserLinkContainer, distance, teaserLinkOffsetTop, teaserLinkHeight, startParallaxAtPx);
					}
				}
			});
			
			$(window).resize(function() {
				curWidth = $(window).width();
				
				if (curWidth > 768) {
					distance = $(window).scrollTop();
					curHeight = $(window).height();
					teaserHeight = $("#teaser").outerHeight();
					threshold = curHeight - navHeight;
					cssBottom = 0;
					cssPosition = "absolute";
									
					if (teaserHeight > curHeight) {
						threshold = teaserHeight - navHeight;
						
						if ((teaserHeight - curHeight) > distance) {
							cssPosition = "fixed";
						} else {
							cssBottom = curHeight - teaserHeight;	
						}					
					}
					
					if (distance >= threshold) {
						nav.css({
							"position": "fixed",
							"top": 0,
							"bottom": "auto"
						});
					}
					else {
						nav.css({
							"position": cssPosition,
							"top": "auto",
							"bottom": cssBottom
						});
					}
				}
			});
			
			// Parallax Effects
			$("#teaser .wrapper-bg.para").parallax({speed: 0.5, axis: 'y'});
		}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JSs
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
	        // die in hell
        } else {
	        $("#people .person.img-right.para").parallax({speed: 0.08, axis: 'x', dir: 'left'});
			$("#people .person.img-left.para").parallax({speed: 0.08, axis: 'x', dir: 'right'});
		}
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
